import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import {FormBuilder, FormGroup, FormArray, FormControl} from '@angular/forms';
import {CertificationService} from '../../../../services/certification.service';
import Swal from "sweetalert2";
import {VerificationService} from '../../../../services/verification.service';
import {ConfigService} from "../../../../services/config.service";

@Component({
  selector: 'app-add-verification',
  templateUrl: './add-verification.component.html',
  styleUrls: ['./add-verification.component.css']
})
export class AddVerificationComponent implements OnInit {
   public verification = {
    name: null,
    site_id: null
  };
  // tslint:disable-next-line:variable-name
  public site_form: any[] = [{
    certificat_ID: ''
  }];
  public sites: any;
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public addingSuccess: boolean;
  public profiles: any;
  public certificats: any;
  public id: any;
  // tslint:disable-next-line:max-line-length
  private myForm: any;
  public siteFormArray: any;
  constructor(private configService: ConfigService, private formBuilder: FormBuilder, private certificationService: CertificationService, private siteService: SiteService, private userService: UserService, private profileService: ProfileService, private verificationService: VerificationService) { }
  public entityType = this.configService.entityType;

  ngOnInit() {
      this.configService.checkUSerAccessByProfile(this.profile_id);
      this.get_list_sites();
      this.get_list_certification();
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  addFormSite() {
    // this.get_list_certification();
    this.site_form.push({
      id: this.site_form.length + 1,
      certificat_ID: ''
    });
  }
  removeFormSite(i: number) {
    this.site_form.splice(i, 1);
  }
  get_list_certification() {
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.certificats = res.data.certificat;
      }, (err) => {
        console.log(err);
      });
  }

  ajouter_verification() {
    // tslint:disable-next-line:max-line-length
    if (this.verification.name === null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Vérifiez vos saisies !'
      });
    }
    else if(this.entityType == 'EKAMPUS'){
        if(this.verification.site_id == null){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Vérifiez vos saisies !'
          });
        }
    }
     else {
      const body = {
        name: this.verification.name,
        certificats: this.site_form,
        site_id: this.verification.site_id
      };
      this.verificationService.addVerification(body)
        .subscribe(res => {
          this.addingSuccess = true;
          this.verification.name = '';
          setTimeout (() => {
            this.addingSuccess = false;
          }, 2000);
        }, (err) => {
          console.log(err);
        });
    }
  }
}
