<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des utilsateurs
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">

              <h4 class="card-title">Ajout d'un utilisateur</h4>
              <div class="alert alert-success text-center" role="alert" *ngIf="success_Message">
                {{text}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="alert alert-danger text-center" role="alert" *ngIf="not_success_Message">
                {{text}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Veuillez sélectionner le profil *</label>
                  <div class="col-md-10">
                    <select class="custom-select" [(ngModel)]="single_client.profile_id">
                      <ng-container *ngIf="profile_id==11">
                        <option value="1">Administrateur de site</option>
                        <option value="6">Superviseur</option>
                        <option value="4">Superviseurs Plus</option>
                        <option value="9">Sup Argent </option>
                        <option value="10">Sup Or </option>
                        <option value="8">Vérificateur</option>
                        <option value="3">Apprenant</option>
                      </ng-container>
                      <ng-container *ngIf="profile_id==1">
                        <option value="6">Superviseur</option>
                        <option value="4">Superviseurs Plus</option>
                        <option value="9">Sup Argent </option>
                        <option value="10">Sup Or </option>
                        <option value="8">Vérificateur</option>
                        <option value="3">Apprenant</option>
                      </ng-container>
                      <ng-container *ngIf="profile_id==2 || profile_id==4 || profile_id==5 || profile_id==6 || profile_id==9 || profile_id==10">
                        <option value="8">Vérificateur</option>
                        <option value="3">Apprenant</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Nom *</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Prénom *</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.firstname">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">E-mail *</label>
                <div class="col-md-10">
                  <input class="form-control" type="email" [(ngModel)]="single_client.email">
                </div>
              </div>
              <div class="form-group row">
                <div class="input-group">
                <label class="col-md-2 col-form-label">Mot de passe *</label>
                  <input class="col-md-10 form-control" type="password" placeholder="Mot de passe" style="margin-left: 12px;border-radius: 5px;" #password1 [(ngModel)]="single_client.password">
                  <div class="input-group-append" style="margin-right: 11px;border-radius: 5px !important;" (click)="changeType(password1, 1)">
                    <span class="input-group-text"><i [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></span>
                  </div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-md-10">
                <i class="bx bx-info-circle"></i>Le mot de passe doit contenir UN caractère spécial, Une lettre majuscule, et UN chiffre
              </div>
              </div>
              <app-password-strength
                [passwordToCheck]="single_client.password"
                (passwordStrength)="passwordValid($event)"
              ></app-password-strength>
              <div class="form-group row">
                <div class="input-group">
                <label class="col-md-2 col-form-label">Confirmation *</label>
                  <input class="form-control" type="password" placeholder="Confirmer votre mot de passe" style="margin-left: 12px;border-radius: 5px;" #password2 [(ngModel)]="single_client.confirm_password">
                  <div class="input-group-append" style="margin-right: 11px;border-radius: 5px !important;" (click)="changeType(password2, 2)">
                    <span class="input-group-text"><i [ngClass]="toggle2 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></span>
                  </div>
              </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Identifiant *</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_client.login">
                </div>
              </div>
              <ng-container *ngIf="entityType == 'EKAMPUS'">
              <ng-container *ngIf="profile_id===11">
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Site *</label>
                <div class="col-md-10">
                  <select class="custom-select" [(ngModel)]="single_client.site_id">
                    <option selected="">Veuillez sélectionner le site</option>
                    <option *ngFor="let site_admin of sites_admin" value="{{site_admin.id}}">{{site_admin.name}}</option>
                  </select>
                </div>
              </div>
              </ng-container>
              <ng-container *ngIf="profile_id!==11">
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Site *</label>
                  <div class="col-md-10">
                    <select class="custom-select" [(ngModel)]="single_client.site_id">
                      <option selected="">Veuillez sélectionner le site</option>
                      <option *ngFor="let site of sites" value="{{site.id}}">{{site.name}}</option>
                    </select>
                  </div>
                </div>
              </ng-container>
              </ng-container>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Publier sur Talensoft ?</label>
                <div class="col-md-10">
                  <select class="custom-select" [(ngModel)]="single_client.publish" (change)="showPublishing($event.target.value)">
                    <option value="0">Non</option>
                    <option value="1">Oui</option>
                  </select>
                </div>
              </div>
              <div class="form-group text-center">
                <ng-container *ngIf="loadingAdd"><img src="assets/img/loaderkapi.gif" height="20"><br></ng-container>
              <button type="button" class="btn btn-primary waves-effect waves-light"  (click)="add_client()">
                  Ajouter
                </button>
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div></div></div></div>
      <!-- End Page-content -->
      <app-footer></app-footer>
