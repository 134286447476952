import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
// @ts-ignore
const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }),
};
export class CertificationService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
    extractData(res) {
        const body = res;
        return body || {};
    }
    getCertificat() {
        return this.http.get(this.config.urlDEV + '/certificats', httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getCertificatBySiteUser(user_id) {
        return this.http.get(this.config.urlDEV + '/certificats/user/' + user_id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getOneCertificat(id) {
        return this.http.get(this.config.urlDEV + '/certificats/' + id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    getSousCertificatbyCertificat(id) {
        return this.http.get(this.config.urlDEV + '/certificat/sous_certificat/' + id, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    addCertificats(data) {
        return this.http.post(this.config.urlDEV + '/certificats', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    updateCertificats(data) {
        return this.http.post(this.config.urlDEV + '/upd_certificats', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    deleteCertificats(data) {
        return this.http.post(this.config.urlDEV + '/del_certificats', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    get_certification_sites_list(id_site) {
        return this.http.get(this.config.urlDEV + '/certifications/site/' + id_site, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    get_sous_certification_sites_list(id_site) {
        return this.http.get(this.config.urlDEV + '/sous_certification/site/' + id_site, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
    // tslint:disable-next-line:variable-name
    get_certification_by_user_login(data) {
        return this.http.post(this.config.urlDEV + '/certifications/users', data, httpOptions).pipe(map(this.extractData), catchError(this.handleError));
    }
}
CertificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CertificationService_Factory() { return new CertificationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: CertificationService, providedIn: "root" });
