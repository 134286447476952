import {Component, OnInit, Renderer2} from '@angular/core';
import {SessionService} from "../../../services/session.service";
import Swal from "sweetalert2";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../../services/user.service";
import {CatalogueService} from "../../../services/catalogue.service";
import {Catalogue} from "../../../models/catalogue.model";
import {ConfigService} from "../../../services/config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormControl} from "@angular/forms";
@Component({
  selector: 'app-catalogue-session',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.css']
})
export class CatalogListComponent implements OnInit {

  constructor(private sessionService: SessionService,
              private spinner: NgxSpinnerService,
              private userService: UserService,
              private catalogueService: CatalogueService,
              private configService: ConfigService,
              private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private router: Router,) { }
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public profile_id = parseInt(localStorage.getItem('profile_id'));

  public user_id;
  public catalogue_langue;

//Forms
  FormSessions: any;
  FormSessionsArray: any;

  ngOnInit() {
    this.catalogue_langue = this.activatedRoute.snapshot.url[4].path;
    this.configService.checkUSerAccessByProfile(this.profile_id);

    if(this.user_conntected.id){
      this.user_id = this.user_conntected.id;
    }
    else{
      this.user_id = this.user_conntected.user_id;
    }

    this.FormSessions = this.formBuilder.group({
      group_id: this.formBuilder.array([])
    });
    this.FormSessionsArray = <FormArray>this.FormSessions.controls.group_id;

    this.getCataloguesByUser(this.catalogue_langue,this.user_id);
  }

  //CATALOGUE
  catalogue: Catalogue; // Catalogue

  getCataloguesByUser(catalogue_langue,user_id){
    this.catalogueService.getCataloguesByUser(catalogue_langue,user_id)
      .subscribe(res => {
        this.catalogue = res.catalogue;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }

  checkSession(id, event : any){
    if(event) {
      this.FormSessionsArray.push(new FormControl(id));
    } else {
      let index = this.FormSessionsArray.controls.findIndex(x => x.value == id)
      this.FormSessionsArray.removeAt(index);
    }
  }

  inscriptionSession() {
    if (this.FormSessionsArray.length < 1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Veuillez sélectionner au moins une session',
      });
      return false;
      // tslint:disable-next-line:align
    }
    const body = {
      user_id : this.user_id,
      session_id : this.FormSessionsArray.value,
    }
    this.sessionService.inscriptionSession(body)
      .subscribe(res => {
        if (res.success == true) {
          Swal.fire(
            'Inscription réussie',
            'Une fois validé, vous pourrez accéder à la/aux formation(s) choisie(s) depuis "Mes formations" sur la page d\'accueil.',
            'success'
          ).then(() => {
            this.redirection();
          });
        }
      }, (err) => {
        console.log(err);
      });
  }

  sso_active() {
    this.spinner.show();
    const body = {
      // ssoKey : 'h-kaptitude'
      Id : this.user_conntected.user_id,
      ssoActive : this.user_conntected.active,
      user_login : this.user_conntected.login
    };

    this.userService.sso_connexion_active(body)
      .subscribe(res => {
        // @ts-ignore
        if (res.sso_data === '') {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Votre compte est inconnu sur Talensoft, veuillez contacter l\'administrateur pour plus détails.',
            footer: '<a href>Equipe Kaptitude</a>'
          });
          return false;
        }
        this.spinner.hide();
        // @ts-ignore
        window.location.replace(this.configService.ELMS_url + 'sso.php?skey=' + res.sso_data);
      }, (err) => {
        console.log(err);
      });
  }

  redirection(){
    this.router.navigate(['dashboard/administrator']);
  }

  formatDescription(description: string): string {
    let formattedDescription = description.replace(/\n/g, '<br>');
    formattedDescription = formattedDescription.replace(/^\* /gm, '<li>');
    formattedDescription = '<ul>' + formattedDescription + '</ul>';
    return formattedDescription;
  }


}
